import { useCallback } from 'react';
import { SupportedObject } from '../components';

export const useHandleChangeSelector = <T extends SupportedObject>(
	originalObject: T,
	setObject: (object: T) => void,
) => {
	const handleChangeSelector = useCallback((field: string, value: string) => {
		const updated: { [key: string]: any } = { ...originalObject };
		updated[field] = value;
		setObject(updated as T);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [originalObject]);
	return handleChangeSelector;
};
