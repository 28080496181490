import { useCallback, useContext, useEffect, useState } from 'react';
import { postItem } from '../API';
import { TYPES_ENUM } from '../API/TYPES';
import { ErrorContext } from '../context';

type ResponseType = {
	isLoadingLabels: boolean,
	labels: LabelsGraph,
};

export const useLabels = (locale: string): ResponseType => {
	const [isLoadingLabels, setIsLoadingLabels] = useState(false);
	const [labels, setLabels] = useState<LabelsGraph>(defaultLabelsGraph);
	const { updateError } = useContext(ErrorContext);

	const loadList = useCallback(async () => {
		setIsLoadingLabels(true);
		setLabels(defaultLabelsGraph);
		try {
			const result = await postItem(TYPES_ENUM.LABEL_GRAPH)(defaultLabelsGraph) as LabelsGraph;
			setLabels(result);
		} catch (error) {
			updateError(error as Error);
		} finally {
			setIsLoadingLabels(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		loadList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadList, locale]);

	return {
		isLoadingLabels,
		labels
	}
};

export interface LabelsGraph {
	name: string,
	myStory: string,
	blog: string,
	projects: string,
	contact: string,
	portfolio: string,
	tags: string,
	ingredients: string,
	recipes: string,
	skills: string,
	transactions: string,
	assets: string,
	accounts: string,
	days: string,
	objectives: string,
	journal: string,
	notes: string,
	movieScreenings: string,
	files: string,
	movies: string,
	labels: string,
	progress: string,
	finance: string,
	publicApp: string,
	adminStuff: string,
	logout: string,
	currentLanguage: string,
	romanianLanguage: string,
	englishLanguage: string,
	frenchLanguage: string,
	transactionBundles: string,
	objectivePlans: string,
	pageNotFound: string,
	fuckSocialMedia: string,
	add: string,
	update: string,
	delete: string,
	payAttention: string,
	back: string,
	title: string,
	currency: string,
	value: string,
	save: string,
	exit: string,
	titleLabel: string,
	totals: string,
	type: string,
	createdDate: string,
	description: string,
	assetType: string,
	RONValue: string,
	deleteAssetAlert: string,
	date: string,
	quote1: string,
	getStarted: string,
	speedAndPerformance: string,
	quote2: string,
	atentionToDetail: string,
	quote3: string,
	teamWork: string,
	quote4: string,
	initiative: string,
	quote5: string,
	previous: string,
	next: string,
	projectArchitecture: string,
	projectArchitectureDetails: string,
	projectStages: string,
	projectStagesDetails: string,
	scrumAndKanban: string,
	scrumAndKanbanDetails: string,
	myMission: string,
	myMissionDetailed: string,
	myPlan: string,
	myPlanDetailed: string,
	myVision: string,
	aboutMe: string,
	myVisionDetailed: string,
	skillsDetailed: string,
	programming: string,
	design: string,
	peopleSkills: string,
	deployment: string,
	footerSectionTitle: string,
	GDPR: string,
	githubLabel: string,
	githubActivityTitle: string,
	gitlabLabel: string,
	gitlabActivityTitle: string,
	grabACV: string,
	forMoreInfoAboutCareer: string,
	learnMore: string,
	userNameLabel: string,
	passwordLabel: string,
	loginButton: string,
	hours: string,
	deploymentUrl: string,
	addProject: string,
	deleteProject: string,
	addBlogPost: string,
	deleteBlogPost: string,
	ascending: string,
	descending: string,
	facebookLabel: string,
	emaiLabel: string,
	phoneLabel: string,
	addressLabel: string,
	youCanGetInTouchUsing: string,
	orUsingThisForm: string,
	yourMessage: string,
	messageSent: string,
	messageNotSent: string,
	send: string,
	address: string,
	linkedInLabel: string,
	cielSalariiDetails: string,
	cielFacturareDetails: string,
	LMS365Detailed: string,
	personalWebsiteDetails: string,
	npmModulesDetails: string,
	psycaptureDetails: string,
	disposableprogressBarTitle: string,
	privateDomainDisclaimer: string,
	updateStory: string,
	addStory: string,
	jobTitle: string,
	startDate: string,
	endDate: string,
	isOngoing: string,
	employer: string,
	language: string,
	summary: string,
	addSkill: string,
	percentage: string,
	removeSkill: string,
	paragraphs: string,
	addParagraph: string,
	createdBy: string,
	git: string,
	version: string,
	details: string,
	updateProject: string,
	updateBlogPost: string,
	status: string,
	content: string,
	addEntry: string,
	updateLogEntry: string,
	location: string,
	deleteSkillAlert: string,
	profile: string,
	deleteObjectiveAlert: string,
	deactivateObjectiveAlert: string,
	target: string,
	userId: string,
	id: string,
	getActivity: string,
	previousSuggestions: string,
	showCalendars: string,
	hideCalendars: string,
	gymActivity: string,
	read20Pages: string,
	pescatarianDiet: string,
	sleepWellDays: string,
	showTimeline: string,
	hideTimeline: string,
	text: string,
	deleteRecordConfirmation: string,
	score: string,
	happiness: string,
	showActivities: string,
	reload: string,
	day: string,
	happinessIndex: string,
	happinessIndexTooltip: string,
	modify: string,
	confirmDeleteTransactionBundle: string,
	inLabel: string,
	outLabel: string,
	balanceLabel: string,
	search: string,
	deleteConfirmationQuestion: string,
	transactionsCode: string,
	sourceAccount: string,
	destinationAccount: string,
	flipChart: string,
	asset: string,
	addTransactionBundle: string,
	updateTransactionBundle: string,
	includedTransactions: string,
	codeLabel: string,
	mustSignIn: string,
	confirmDeleteTag: string,
	designation: string,
	movieName: string,
	platform: string,
	movie: string,
	series: string,
	documentary: string,
	standup: string,
	netflix: string,
	filelist: string,
	cinema: string,
	createNewMovieScreening: string,
	addTag: string,
	updateTag: string,
	spanishLanguage: string,
	italianLanguage: string,
	imdbUrl: string,
	confirmDeleteIngredient: string,
	nameLabel: string,
	addIngredient: string,
	updateIngredient: string,
	confirmDeleteRecipe: string,
	addRecipe: string,
	updateRecipe: string,
	dueDate: string,
	finishDate: string,
	priority: string,
	targetMissedTemplate: string,
	completed: string,
	fail: string,
	cancelButton: string,
	todo: string,
	done: string,
	overdue: string,
	failed: string,
	abandoned: string,
	low: string,
	medium: string,
	high: string,
	critical: string,
	percentageComplete: string,
	objectivesCount: string,
	maxScore: string,
	objectivePlanDeleteConfirmation: string,
	addTemplate: string,
	updateTemplate: string,
	objectivePlan: string,
	multiplier: string,
	availableObjectives: string,
	includedObjectives: string,
	disclaimerPara1: string,
	disclaimerPara2: string,
	disclaimerPara3: string,
	disclaimerPara4: string,
	disclaimerPara5: string,
	disclaimerPara6: string,
	disclaimerPara7: string,
	books: string,
	authors: string,
	fieldIsMandatory: string,
	ownedBooks: string,
	dateIn: string,
	bookName: string,
	bookReadings: string,
	wasFinished: string,
	wishedBooks: string,
	active: string,
	disposedFromHeritage: string,
	timeline: string,
	myBooks: string,
	useTransactionsInthisInterval: string,
	hasBookReadingsOnTimeline: string,
	hasBookReading: string,
	accountingRecords: string,
	creditAccount: string,
	debitAccount: string,
	hasMovieScreeningsOnTimeline: string,
	hasMovieScreenings: string,
	encashment: string,
	payment: string,
	internalTransfer: string,
	hasCalculatedTotals: string,
	externalEntities: string,
	category: string,
	categoryBills: string,
	categoryTransport: string,
	categoryParty: string,
	categoryClothes: string,
	categoryMisc: string,
	categoryFood: string,
	categoryHome: string,
	categoryEmployer: string,
	categoryProject: string,
	categoryCareer: string,
	categoryHealth: string,
	categoryGames: string,
	categoryTravel: string,
	categoryBanks: string,
	categoryInvestments: string,
	myAccount: string,
	myAccountDetails: string,
	usedCurrency: string,
	exchangeRates: string,
	valueInSelectedCurrency: string,
	accountRole: string,
	currentAccount: string,
	carLabel: string,
	cash: string,
	deposit: string,
	homeElectronics: string,
	investmentFunds: string,
	realEstate: string,
	savingsAccount: string,
	treasuryBonds: string,
	healthAppTitle: string,
	workouts: string,
	addEvent: string,
	modifyEvent: string,
	timelineSectionCollapsedWarning: string,
	resultSectionCollapsedWarning: string,
	minimaListAppName: string,
	myInventory: string,
	myCategories: string,
}

export const defaultLabelsGraph: LabelsGraph = {
	name: '',
	myStory: '',
	blog: '',
	projects: '',
	contact: '',
	portfolio: '',
	tags: '',
	ingredients: '',
	recipes: '',
	skills: '',
	transactions: '',
	assets: '',
	accounts: '',
	days: '',
	objectives: '',
	journal: '',
	notes: '',
	movieScreenings: '',
	files: '',
	movies: '',
	labels: '',
	progress: '',
	finance: '',
	publicApp: '',
	adminStuff: '',
	logout: '',
	currentLanguage: '',
	romanianLanguage: '',
	englishLanguage: '',
	frenchLanguage: '',
	transactionBundles: '',
	objectivePlans: '',
	pageNotFound: '',
	fuckSocialMedia: '',
	add: '',
	update: '',
	delete: '',
	payAttention: '',
	back: '',
	title: '',
	currency: '',
	value: '',
	save: '',
	exit: '',
	titleLabel: '',
	totals: '',
	type: '',
	createdDate: '',
	description: '',
	assetType: '',
	RONValue: '',
	deleteAssetAlert: '',
	date: '',
	quote1: '',
	getStarted: '',
	speedAndPerformance: '',
	quote2: '',
	atentionToDetail: '',
	quote3: '',
	teamWork: '',
	quote4: '',
	initiative: '',
	quote5: '',
	previous: '',
	next: '',
	projectArchitecture: '',
	projectArchitectureDetails: '',
	projectStages: '',
	projectStagesDetails: '',
	scrumAndKanban: '',
	scrumAndKanbanDetails: '',
	myMission: '',
	myMissionDetailed: '',
	myPlan: '',
	myPlanDetailed: '',
	myVision: '',
	aboutMe: '',
	myVisionDetailed: '',
	skillsDetailed: '',
	programming: '',
	design: '',
	peopleSkills: '',
	deployment: '',
	footerSectionTitle: '',
	GDPR: '',
	githubLabel: '',
	githubActivityTitle: '',
	gitlabLabel: '',
	gitlabActivityTitle: '',
	grabACV: '',
	forMoreInfoAboutCareer: '',
	learnMore: '',
	userNameLabel: '',
	passwordLabel: '',
	loginButton: '',
	hours: '',
	deploymentUrl: '',
	addProject: '',
	deleteProject: '',
	addBlogPost: '',
	deleteBlogPost: '',
	ascending: '',
	descending: '',
	facebookLabel: '',
	emaiLabel: '',
	phoneLabel: '',
	addressLabel: '',
	youCanGetInTouchUsing: '',
	orUsingThisForm: '',
	yourMessage: '',
	messageSent: '',
	messageNotSent: '',
	send: '',
	address: '',
	linkedInLabel: '',
	cielSalariiDetails: '',
	cielFacturareDetails: '',
	LMS365Detailed: '',
	personalWebsiteDetails: '',
	npmModulesDetails: '',
	psycaptureDetails: '',
	disposableprogressBarTitle: '',
	privateDomainDisclaimer: '',
	updateStory: '',
	addStory: '',
	jobTitle: '',
	startDate: '',
	endDate: '',
	isOngoing: '',
	employer: '',
	language: '',
	summary: '',
	addSkill: '',
	percentage: '',
	removeSkill: '',
	paragraphs: '',
	addParagraph: '',
	createdBy: '',
	git: '',
	version: '',
	details: '',
	updateProject: '',
	updateBlogPost: '',
	status: '',
	content: '',
	addEntry: '',
	updateLogEntry: '',
	location: '',
	deleteSkillAlert: '',
	profile: '',
	deleteObjectiveAlert: '',
	deactivateObjectiveAlert: '',
	target: '',
	userId: '',
	id: '',
	getActivity: '',
	previousSuggestions: '',
	showCalendars: '',
	hideCalendars: '',
	gymActivity: '',
	read20Pages: '',
	pescatarianDiet: '',
	sleepWellDays: '',
	showTimeline: '',
	hideTimeline: '',
	text: '',
	deleteRecordConfirmation: '',
	score: '',
	happiness: '',
	showActivities: '',
	reload: '',
	day: '',
	happinessIndex: '',
	happinessIndexTooltip: '',
	modify: '',
	confirmDeleteTransactionBundle: '',
	inLabel: '',
	outLabel: '',
	balanceLabel: '',
	search: '',
	deleteConfirmationQuestion: '',
	transactionsCode: '',
	sourceAccount: '',
	destinationAccount: '',
	flipChart: '',
	asset: '',
	addTransactionBundle: '',
	updateTransactionBundle: '',
	includedTransactions: '',
	codeLabel: '',
	mustSignIn: '',
	confirmDeleteTag: '',
	designation: '',
	movieName: '',
	platform: '',
	movie: '',
	series: '',
	documentary: '',
	standup: '',
	netflix: '',
	filelist: '',
	cinema: '',
	createNewMovieScreening: '',
	addTag: '',
	updateTag: '',
	spanishLanguage: '',
	italianLanguage: '',
	imdbUrl: '',
	confirmDeleteIngredient: '',
	nameLabel: '',
	addIngredient: '',
	updateIngredient: '',
	confirmDeleteRecipe: '',
	addRecipe: '',
	updateRecipe: '',
	dueDate: '',
	finishDate: '',
	priority: '',
	targetMissedTemplate: '',
	completed: '',
	fail: '',
	cancelButton: '',
	todo: '',
	done: '',
	overdue: '',
	failed: '',
	abandoned: '',
	low: '',
	medium: '',
	high: '',
	critical: '',
	percentageComplete: '',
	objectivesCount: '',
	maxScore: '',
	objectivePlanDeleteConfirmation: '',
	addTemplate: '',
	updateTemplate: '',
	objectivePlan: '',
	multiplier: '',
	availableObjectives: '',
	includedObjectives: '',
	disclaimerPara1: '',
	disclaimerPara2: '',
	disclaimerPara3: '',
	disclaimerPara4: '',
	disclaimerPara5: '',
	disclaimerPara6: '',
	disclaimerPara7: '',
	books: '',
	authors: '',
	fieldIsMandatory: '',
	ownedBooks: '',
	dateIn: '',
	bookName: '',
	bookReadings: '',
	wasFinished: '',
	wishedBooks: '',
	active: '',
	disposedFromHeritage: '',
	timeline: '',
	myBooks: '',
	useTransactionsInthisInterval: '',
	hasBookReadingsOnTimeline: '',
	hasBookReading: '',
	accountingRecords: '',
	creditAccount: '',
	debitAccount: '',
	hasMovieScreeningsOnTimeline: '',
	hasMovieScreenings: '',
	encashment: '',
	payment: '',
	internalTransfer: '',
	hasCalculatedTotals: '',
	externalEntities: '',
	category: '',
	categoryBills: '',
	categoryTransport: '',
	categoryParty: '',
	categoryClothes: '',
	categoryMisc: '',
	categoryFood: '',
	categoryHome: '',
	categoryEmployer: '',
	categoryProject: '',
	categoryCareer: '',
	categoryHealth: '',
	categoryGames: '',
	categoryTravel: '',
	categoryBanks: '',
	categoryInvestments: '',
	myAccount: '',
	myAccountDetails: '',
	usedCurrency: '',
	exchangeRates: '',
	valueInSelectedCurrency: '',
	accountRole: '',
	currentAccount: '',
	carLabel: '',
	cash: '',
	deposit: '',
	homeElectronics: '',
	investmentFunds: '',
	realEstate: '',
	savingsAccount: '',
	treasuryBonds: '',
	healthAppTitle: '',
	workouts: '',
	addEvent: '',
	modifyEvent: '',
	timelineSectionCollapsedWarning: '',
	resultSectionCollapsedWarning: '',
	minimaListAppName: '',
	myInventory: '',
	myCategories: '',
}