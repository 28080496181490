import React, { useCallback } from 'react';
import { SupportedObject } from '../components';
import { getInputChangeParams } from '../utils';

export const useHandleInputChange = <T extends SupportedObject>(
	originalObject: T,
	setObject: (object: T) => void,
) => {
	const handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLInputElement> = useCallback(
		(e) => {
			let [field, value] = getInputChangeParams<string, string>(e);
			const updated: { [key: string]: any } = { ...originalObject };
			updated[field] = value;
			setObject(updated as T);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[originalObject]);
	return handleInputChange;
};