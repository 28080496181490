import { useCallback, useState } from 'react';
import { remove } from 'lodash';

type UseAPIListTuple<T> = {
	select: (newSelected: T[]) => void,
	selectAll: () => void,
	overwriteSelected: (newSelected: T[]) => void,
	selected: T[]
}

export const useListSelection = <T>(
	initialList: T[],
	finder: (a: T, b: T) => boolean,
): UseAPIListTuple<T> => {
	const [list] = useState<T[]>(initialList);
	const [selected, setSelected] = useState<T[]>([]);

	const select = useCallback(
		(newSelectedAccounts: T[]) => {
			if (!newSelectedAccounts.length) {
				setSelected([]);
				return;
			}
			for (let i = 0; i < newSelectedAccounts.length; i++) {
				const newSelectedAccount = newSelectedAccounts[i];
				let found = selected.find(sl => finder(sl, newSelectedAccount));
				if (found) {
					remove(selected, sl => finder(sl, newSelectedAccount));
				} else {
					selected.push(newSelectedAccount);
				}
			}
			setSelected([...selected]);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selected]);

	const selectAll = useCallback(
		() => {
			if (selected.length && selected.length === list.length) {
				setSelected([]);
				return;
			}
			setSelected([...list]);
			return;
		},
		[list, selected]
	);

	const overwriteSelected = useCallback(
		(newSelected: T[]) => {
			if (selected[0] && finder(selected[0], newSelected[0])) {
				setSelected([]);
				return;
			}
			setSelected(newSelected);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selected]
	);

	return {
		select,
		selectAll,
		overwriteSelected,
		selected
	};
}